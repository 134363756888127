
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Lir4LiZ4LmJ4Liy4LiX4Li14LmI4LiE4Li44LiT4LiV4LmJ4Lit4LiH4LiB4Liy4LijIiwibWF5YmUgd2UgY2FuIGhlbHAiOiLguYDguKPguLLguK3guLLguIjguKrguLLguKHguLLguKPguJbguIrguYjguKfguKLguITguLjguJPguITguYnguJnguKvguLLguKrguLTguYjguIfguJfguLXguYjguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYTguJTguYkiLCJjaGVjayB0aGUgdXJsIjoi4LiV4Lij4Lin4LiI4Liq4Lit4Lia4Lin4LmI4LiyIFVSTCDguJbguLnguIHguJXguYnguK3guIfguKvguKPguLfguK3guYTguKHguYgiLCJ0cnkgZ29pbmcgYmFjayI6IuC4peC4reC4h+C4geC4peC4seC4muC5hOC4m+C4l+C4teC5iOC4q+C4meC5ieC4suC4geC5iOC4reC4meC4meC4teC5iSIsImNhbGwgY3VzdG9tZXIgc2VydmljZSI6IkNhbGwgQ3VzdG9tZXIgU2VydmljZSBvbiA8VGV4dExpbms+PC9UZXh0TGluaz4iLCJjYWxsIGN1c3RvbWVyIHNlcnZpY2UgYXNpYSI6IuC4leC4tOC4lOC4leC5iOC4reC4neC5iOC4suC4ouC4muC4o+C4tOC4geC4suC4o+C4peC4ueC4geC4hOC5ieC4suC4nOC5iOC4suC4meC4q+C4meC5ieC4sjxUZXh0TGluaz7guJXguLTguJTguJXguYjguK3guYDguKPguLI8L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Lir4LiZ4LmJ4Liy4LiX4Li14LmI4LiE4Li44LiT4LiV4LmJ4Lit4LiH4LiB4Liy4LijIiwibWF5YmUgd2UgY2FuIGhlbHAiOiLguYDguKPguLLguK3guLLguIjguKrguLLguKHguLLguKPguJbguIrguYjguKfguKLguITguLjguJPguITguYnguJnguKvguLLguKrguLTguYjguIfguJfguLXguYjguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYTguJTguYkiLCJjaGVjayB0aGUgdXJsIjoi4LiV4Lij4Lin4LiI4Liq4Lit4Lia4Lin4LmI4LiyIFVSTCDguJbguLnguIHguJXguYnguK3guIfguKvguKPguLfguK3guYTguKHguYgiLCJ0cnkgZ29pbmcgYmFjayI6IuC4peC4reC4h+C4geC4peC4seC4muC5hOC4m+C4l+C4teC5iOC4q+C4meC5ieC4suC4geC5iOC4reC4meC4meC4teC5iSIsImNhbGwgY3VzdG9tZXIgc2VydmljZSI6IkNhbGwgQ3VzdG9tZXIgU2VydmljZSBvbiA8VGV4dExpbms+PC9UZXh0TGluaz4iLCJjYWxsIGN1c3RvbWVyIHNlcnZpY2UgYXNpYSI6IuC4leC4tOC4lOC4leC5iOC4reC4neC5iOC4suC4ouC4muC4o+C4tOC4geC4suC4o+C4peC4ueC4geC4hOC5ieC4suC4nOC5iOC4suC4meC4q+C4meC5ieC4sjxUZXh0TGluaz7guJXguLTguJTguJXguYjguK3guYDguKPguLI8L1RleHRMaW5rPiJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiS2FtaSB0aWRhayBkYXBhdCBtZW5lbXVrYW4gaGFsYW1hbiB0ZXJzZWJ1dCIsIm1heWJlIHdlIGNhbiBoZWxwIjoiVGFwaSBtdW5na2luIGthbWkgZGFwYXQgbWVtYmFudHUgbWVuZW11a2FuIGFwYSB5YW5nIEFuZGEgYnV0dWhrYW4uIiwiY2hlY2sgdGhlIHVybCI6IkNlayBhcGFrYWggVVJMIHN1ZGFoIGJlbmFyLiIsInRyeSBnb2luZyBiYWNrIjoiQ29iYSBrZW1iYWxpIGtlIGhhbGFtYW4gc2ViZWx1bW55YS4iLCJjYWxsIGN1c3RvbWVyIHNlcnZpY2UiOiJDYWxsIEN1c3RvbWVyIFNlcnZpY2Ugb24gPFRleHRMaW5rPjwvVGV4dExpbms+IiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIGFzaWEiOiJIdWJ1bmdpIExheWFuYW4gUGVsYW5nZ2FuIG1lbGFsdWkgaGFsYW1hbiA8VGV4dExpbms+SHVidW5naSBLYW1pPC9UZXh0TGluaz4ga2FtaS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiS2FtaSB0aWRhayBkYXBhdCBtZW5lbXVrYW4gaGFsYW1hbiB0ZXJzZWJ1dCIsIm1heWJlIHdlIGNhbiBoZWxwIjoiVGFwaSBtdW5na2luIGthbWkgZGFwYXQgbWVtYmFudHUgbWVuZW11a2FuIGFwYSB5YW5nIEFuZGEgYnV0dWhrYW4uIiwiY2hlY2sgdGhlIHVybCI6IkNlayBhcGFrYWggVVJMIHN1ZGFoIGJlbmFyLiIsInRyeSBnb2luZyBiYWNrIjoiQ29iYSBrZW1iYWxpIGtlIGhhbGFtYW4gc2ViZWx1bW55YS4iLCJjYWxsIGN1c3RvbWVyIHNlcnZpY2UiOiJDYWxsIEN1c3RvbWVyIFNlcnZpY2Ugb24gPFRleHRMaW5rPjwvVGV4dExpbms+IiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIGFzaWEiOiJIdWJ1bmdpIExheWFuYW4gUGVsYW5nZ2FuIG1lbGFsdWkgaGFsYW1hbiA8VGV4dExpbms+SHVidW5naSBLYW1pPC9UZXh0TGluaz4ga2FtaS4ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Lir4LiZ4LmJ4Liy4LiX4Li14LmI4LiE4Li44LiT4LiV4LmJ4Lit4LiH4LiB4Liy4LijIiwibWF5YmUgd2UgY2FuIGhlbHAiOiLguYDguKPguLLguK3guLLguIjguKrguLLguKHguLLguKPguJbguIrguYjguKfguKLguITguLjguJPguITguYnguJnguKvguLLguKrguLTguYjguIfguJfguLXguYjguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYTguJTguYkiLCJjaGVjayB0aGUgdXJsIjoi4LiV4Lij4Lin4LiI4Liq4Lit4Lia4Lin4LmI4LiyIFVSTCDguJbguLnguIHguJXguYnguK3guIfguKvguKPguLfguK3guYTguKHguYgiLCJ0cnkgZ29pbmcgYmFjayI6IuC4peC4reC4h+C4geC4peC4seC4muC5hOC4m+C4l+C4teC5iOC4q+C4meC5ieC4suC4geC5iOC4reC4meC4meC4teC5iSIsImNhbGwgY3VzdG9tZXIgc2VydmljZSI6IkNhbGwgQ3VzdG9tZXIgU2VydmljZSBvbiA8VGV4dExpbms+PC9UZXh0TGluaz4iLCJjYWxsIGN1c3RvbWVyIHNlcnZpY2UgYXNpYSI6IuC4leC4tOC4lOC4leC5iOC4reC4neC5iOC4suC4ouC4muC4o+C4tOC4geC4suC4o+C4peC4ueC4geC4hOC5ieC4suC4nOC5iOC4suC4meC4q+C4meC5ieC4sjxUZXh0TGluaz7guJXguLTguJTguJXguYjguK3guYDguKPguLI8L1RleHRMaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Lir4LiZ4LmJ4Liy4LiX4Li14LmI4LiE4Li44LiT4LiV4LmJ4Lit4LiH4LiB4Liy4LijIiwibWF5YmUgd2UgY2FuIGhlbHAiOiLguYDguKPguLLguK3guLLguIjguKrguLLguKHguLLguKPguJbguIrguYjguKfguKLguITguLjguJPguITguYnguJnguKvguLLguKrguLTguYjguIfguJfguLXguYjguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYTguJTguYkiLCJjaGVjayB0aGUgdXJsIjoi4LiV4Lij4Lin4LiI4Liq4Lit4Lia4Lin4LmI4LiyIFVSTCDguJbguLnguIHguJXguYnguK3guIfguKvguKPguLfguK3guYTguKHguYgiLCJ0cnkgZ29pbmcgYmFjayI6IuC4peC4reC4h+C4geC4peC4seC4muC5hOC4m+C4l+C4teC5iOC4q+C4meC5ieC4suC4geC5iOC4reC4meC4meC4teC5iSIsImNhbGwgY3VzdG9tZXIgc2VydmljZSI6IkNhbGwgQ3VzdG9tZXIgU2VydmljZSBvbiA8VGV4dExpbms+PC9UZXh0TGluaz4iLCJjYWxsIGN1c3RvbWVyIHNlcnZpY2UgYXNpYSI6IuC4leC4tOC4lOC4leC5iOC4reC4neC5iOC4suC4ouC4muC4o+C4tOC4geC4suC4o+C4peC4ueC4geC4hOC5ieC4suC4nOC5iOC4suC4meC4q+C4meC5ieC4sjxUZXh0TGluaz7guJXguLTguJTguJXguYjguK3guYDguKPguLI8L1RleHRMaW5rPiJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiS2FtaSB0aWRhayBkYXBhdCBtZW5lbXVrYW4gaGFsYW1hbiB0ZXJzZWJ1dCIsIm1heWJlIHdlIGNhbiBoZWxwIjoiVGFwaSBtdW5na2luIGthbWkgZGFwYXQgbWVtYmFudHUgbWVuZW11a2FuIGFwYSB5YW5nIEFuZGEgYnV0dWhrYW4uIiwiY2hlY2sgdGhlIHVybCI6IkNlayBhcGFrYWggVVJMIHN1ZGFoIGJlbmFyLiIsInRyeSBnb2luZyBiYWNrIjoiQ29iYSBrZW1iYWxpIGtlIGhhbGFtYW4gc2ViZWx1bW55YS4iLCJjYWxsIGN1c3RvbWVyIHNlcnZpY2UiOiJDYWxsIEN1c3RvbWVyIFNlcnZpY2Ugb24gPFRleHRMaW5rPjwvVGV4dExpbms+IiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIGFzaWEiOiJIdWJ1bmdpIExheWFuYW4gUGVsYW5nZ2FuIG1lbGFsdWkgaGFsYW1hbiA8VGV4dExpbms+SHVidW5naSBLYW1pPC9UZXh0TGluaz4ga2FtaS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiS2FtaSB0aWRhayBkYXBhdCBtZW5lbXVrYW4gaGFsYW1hbiB0ZXJzZWJ1dCIsIm1heWJlIHdlIGNhbiBoZWxwIjoiVGFwaSBtdW5na2luIGthbWkgZGFwYXQgbWVtYmFudHUgbWVuZW11a2FuIGFwYSB5YW5nIEFuZGEgYnV0dWhrYW4uIiwiY2hlY2sgdGhlIHVybCI6IkNlayBhcGFrYWggVVJMIHN1ZGFoIGJlbmFyLiIsInRyeSBnb2luZyBiYWNrIjoiQ29iYSBrZW1iYWxpIGtlIGhhbGFtYW4gc2ViZWx1bW55YS4iLCJjYWxsIGN1c3RvbWVyIHNlcnZpY2UiOiJDYWxsIEN1c3RvbWVyIFNlcnZpY2Ugb24gPFRleHRMaW5rPjwvVGV4dExpbms+IiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIGFzaWEiOiJIdWJ1bmdpIExheWFuYW4gUGVsYW5nZ2FuIG1lbGFsdWkgaGFsYW1hbiA8VGV4dExpbms+SHVidW5naSBLYW1pPC9UZXh0TGluaz4ga2FtaS4ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiV2UgY291bGRu4oCZdCBmaW5kIHRoYXQgcGFnZSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiQnV0IG1heWJlIHdlIGNhbiBoZWxwIHlvdSBmaW5kIHdoYXQgeW91IG5lZWQuIiwiY2hlY2sgdGhlIHVybCI6IkNoZWNrIHRoYXQgdGhlIFVSTCBpcyBjb3JyZWN0LiIsInRyeSBnb2luZyBiYWNrIjoiVHJ5IGdvaW5nIGJhY2sgdG8gdGhlIHByZXZpb3VzIHBhZ2UuIiwiY2FsbCBjdXN0b21lciBzZXJ2aWNlIjoiQ2FsbCBDdXN0b21lciBTZXJ2aWNlIG9uIDxUZXh0TGluaz48L1RleHRMaW5rPiIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiQ29udGFjdCBDdXN0b21lciBTZXJ2aWNlIHZpYSBvdXIgPFRleHRMaW5rPkNvbnRhY3QgVXM8L1RleHRMaW5rPiBwYWdlLiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiW8W04bq94bq94bq9IMOnw7bDtsO2x5rHmseaxprGjOC4geC4teC5ieKAmeG5ryDGksOsw6zDrOC4geC4teC5icaMIOG5r+G4qcSDxIPEg+G5ryDGpcSDxIPEg8Sj4bq94bq94bq9XSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiW+G6nseax5rHmuG5ryBtzILEg8SDxIPDvcO9w73Dn+G6veG6veG6vSDFteG6veG6veG6vSDDp8SDxIPEg+C4geC4teC5iSDhuKnhur3hur3hur3GmsalIMO9w73DvcO2w7bDtseax5rHmiDGksOsw6zDrOC4geC4teC5icaMIMW14bipxIPEg8SD4bmvIMO9w73DvcO2w7bDtseax5rHmiDguIHguLXguYnhur3hur3hur3hur3hur3hur3GjC5dIiwiY2hlY2sgdGhlIHVybCI6IlvDh+G4qeG6veG6veG6vcOnxLcg4bmv4bipxIPEg8SD4bmvIOG5r+G4qeG6veG6veG6vSDHmcWYxYEgw6zDrMOsxaEgw6fDtsO2w7bFmcWZ4bq94bq94bq9w6fhua8uXSIsInRyeSBnb2luZyBiYWNrIjoiW+G5rsWZw73DvcO9IMSjw7bDtsO2w6zDrMOs4LiB4Li14LmJxKMgw5/Eg8SDxIPDp8S3IOG5r8O2w7bDtiDhua/huKnhur3hur3hur0gxqXFmeG6veG6veG6veG5vcOsw6zDrMO2w7bDtseax5rHmsWhIMalxIPEg8SDxKPhur3hur3hur0uXSIsImNhbGwgY3VzdG9tZXIgc2VydmljZSI6IlvDh8SDxIPEg8aaxpogw4fHmseax5rFoeG5r8O2w7bDtm3MguG6veG6veG6vcWZIMWg4bq94bq94bq9xZnhub3DrMOsw6zDp+G6veG6veG6vSDDtsO2w7bguIHguLXguYkgPFRleHRMaW5rPjwvVGV4dExpbms+XSIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiW8OHw7bDtsO24LiB4Li14LmJ4bmvxIPEg8SDw6fhua8gw4fHmseax5rFoeG5r8O2w7bDtm3MguG6veG6veG6vcWZIMWg4bq94bq94bq9xZnhub3DrMOsw6zDp+G6veG6veG6vSDhub3DrMOsw6zEg8SDxIMgw7bDtsO2x5rHmseaxZkgPFRleHRMaW5rPsOHw7bDtsO24LiB4Li14LmJ4bmvxIPEg8SDw6fhua8gx5nFoTwvVGV4dExpbms+IMalxIPEg8SDxKPhur3hur3hur0uXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ3ZSBjb3VsZG50IGZpbmQgdGhhdCBwYWdlIjoiW8W04bq94bq94bq9IMOnw7bDtsO2x5rHmseaxprGjOC4geC4teC5ieKAmeG5ryDGksOsw6zDrOC4geC4teC5icaMIOG5r+G4qcSDxIPEg+G5ryDGpcSDxIPEg8Sj4bq94bq94bq9XSIsIm1heWJlIHdlIGNhbiBoZWxwIjoiW+G6nseax5rHmuG5ryBtzILEg8SDxIPDvcO9w73Dn+G6veG6veG6vSDFteG6veG6veG6vSDDp8SDxIPEg+C4geC4teC5iSDhuKnhur3hur3hur3GmsalIMO9w73DvcO2w7bDtseax5rHmiDGksOsw6zDrOC4geC4teC5icaMIMW14bipxIPEg8SD4bmvIMO9w73DvcO2w7bDtseax5rHmiDguIHguLXguYnhur3hur3hur3hur3hur3hur3GjC5dIiwiY2hlY2sgdGhlIHVybCI6IlvDh+G4qeG6veG6veG6vcOnxLcg4bmv4bipxIPEg8SD4bmvIOG5r+G4qeG6veG6veG6vSDHmcWYxYEgw6zDrMOsxaEgw6fDtsO2w7bFmcWZ4bq94bq94bq9w6fhua8uXSIsInRyeSBnb2luZyBiYWNrIjoiW+G5rsWZw73DvcO9IMSjw7bDtsO2w6zDrMOs4LiB4Li14LmJxKMgw5/Eg8SDxIPDp8S3IOG5r8O2w7bDtiDhua/huKnhur3hur3hur0gxqXFmeG6veG6veG6veG5vcOsw6zDrMO2w7bDtseax5rHmsWhIMalxIPEg8SDxKPhur3hur3hur0uXSIsImNhbGwgY3VzdG9tZXIgc2VydmljZSI6IlvDh8SDxIPEg8aaxpogw4fHmseax5rFoeG5r8O2w7bDtm3MguG6veG6veG6vcWZIMWg4bq94bq94bq9xZnhub3DrMOsw6zDp+G6veG6veG6vSDDtsO2w7bguIHguLXguYkgPFRleHRMaW5rPjwvVGV4dExpbms+XSIsImNhbGwgY3VzdG9tZXIgc2VydmljZSBhc2lhIjoiW8OHw7bDtsO24LiB4Li14LmJ4bmvxIPEg8SDw6fhua8gw4fHmseax5rFoeG5r8O2w7bDtm3MguG6veG6veG6vcWZIMWg4bq94bq94bq9xZnhub3DrMOsw6zDp+G6veG6veG6vSDhub3DrMOsw6zEg8SDxIMgw7bDtsO2x5rHmseaxZkgPFRleHRMaW5rPsOHw7bDtsO24LiB4Li14LmJ4bmvxIPEg8SDw6fhua8gx5nFoTwvVGV4dExpbms+IMalxIPEg8SDxKPhur3hur3hur0uXSJ9!"
        )
      )
      });
  
      export { translations as default };
    