import { CUSTOMER_SERVICE_PHONE_NUMBER } from '@seek/shared-constants';
import { useTranslations } from '@vocab/react';
import { Heading, Stack, Text, List } from 'braid-design-system';
import React from 'react';

import { StaticPageLayout, TextLink } from 'src/components';
import { ErrorWrapper } from 'src/components/ErrorWrapper/ErrorWrapper';
import { TRACKING_CODES } from 'src/shared/constant';
import { useConfig } from 'src/shared/hooks';
import type {
  LayoutProps,
  PageTrackingProps,
  PhoneNumber,
} from 'src/shared/types';

import translations from './.vocab';

const layoutProps: LayoutProps = {
  title: '404 Page Not Found',
  returnUrl: '/',
  isErrorPage: true,
};

const NotFound = () => {
  const { t } = useTranslations(translations);
  const { isAnz, site } = useConfig();
  const trackingProps: PageTrackingProps = {
    currentPage: 'error page',
    eventName: TRACKING_CODES.error_page_displayed,
    siteSection: 'other',
    errorCode: '404',
    errorMessage: 'page not found',
  };

  return (
    <StaticPageLayout
      layoutProps={{ ...layoutProps, noFrame: site.includes('root') }}
      trackingProps={trackingProps}
    >
      <ErrorWrapper>
        <Stack space="gutter" align="center">
          <Heading level="3">{t('we couldnt find that page')}</Heading>
          <Stack space="gutter" align="left">
            <Text>{t('maybe we can help')}</Text>
            <List type="number" space="medium">
              <Text>{t('check the url')}</Text>
              <Text>{t('try going back')}</Text>
              <Text>
                {isAnz
                  ? t('call customer service', {
                      TextLink: () => (
                        <TextLink
                          href={`tel: ${
                            (CUSTOMER_SERVICE_PHONE_NUMBER as PhoneNumber)[site]
                          }`}
                          tracking={TRACKING_CODES.can_help_centre_link_clicked}
                        >
                          {(CUSTOMER_SERVICE_PHONE_NUMBER as PhoneNumber)[site]}
                        </TextLink>
                      ),
                    })
                  : t('call customer service asia', {
                      TextLink: (children) => (
                        <TextLink
                          href="/contact-us?enquiry=page-not-found"
                          tracking={TRACKING_CODES.contact_us_link_clicked}
                        >
                          {children}
                        </TextLink>
                      ),
                    })}
              </Text>
            </List>
          </Stack>
        </Stack>
      </ErrorWrapper>
    </StaticPageLayout>
  );
};

export default NotFound;
